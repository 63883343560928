import * as React from "react";

import PageLayout from "../components/PageLayout";
import Header from "../components/Header";
import TryItSection from "../sections/IndexPage/TryIt";
import FeaturesSection from "../sections/IndexPage/Features";
import GetStartedSection from "../sections/IndexPage/GetStarted";
import Footer from "../components/Footer";

const IndexPage = () => {
  return (
    <>
      <Header />
      <PageLayout title="Fearn">
        <TryItSection />
        <FeaturesSection />
        <GetStartedSection />
        <Footer />
      </PageLayout>
    </>
  );
};

export default IndexPage;
